import React from "react";
import "./footer.scss";

function Footer() {
    return (
        <div className="bg-black py-5">
            <div className="container text-center">
                <div className="row pb-4">
                    <div className="col-12 col-md-3"></div>
                    <div className="col-4 col-md-2">
                        <a href="https://www.instagram.com/mit.seb/"><i className="bi bi-instagram footer-icon"></i></a>
                    </div>
                    <div className="col-4 col-md-2">
                        <a href="https://www.facebook.com/mitseb"><i className="bi bi-facebook footer-icon"></i></a>
                    </div>
                    <div className="col-4 col-md-2">
                        <a href="mailto:student-events@mit.edu"><i className="bi bi-envelope footer-icon"></i></a>
                    </div>
                    <div className="col-12 col-md-3"></div>
                </div>
                <div className="row">
                    <div className="col">
                        <h2 onClick={() => window.scrollTo(0,0)} className="footer-action-text">top</h2>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Footer;