/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./card.scss";

const nextImageTime = 4000;

function Card({title, body, thumbnail=[]}) {
    const [thumbIdx, setThumbIdx] = useState(0);

    function calculateIdx(prevIdx, delta) {
        if (prevIdx + delta >= thumbnail.length) {
            return 0;
        } else if (prevIdx + delta < 0) {
            return thumbnail.length - 1;
        } else {
            return prevIdx + delta;
        }
    }

    // useEffect(() => {
    //     if (thumbnail.length > 1) {
    //         const intervalId = window.setInterval(() => {
    //             setThumbIdx(prevIdx => calculateIdx(prevIdx, 1));
    //         }, nextImageTime);
    
    //         return () => window.clearInterval(intervalId);
    //     }
    // }, []);

    return (
        <div className="card my-5">
            <div className="card-thumbnail">
                {
                    thumbnail.length > 0 ?
                        (
                            <>
                                <img className="card-thumbnail" src={thumbnail[thumbIdx]} />
                                {
                                    thumbnail.length > 1 ?
                                        (
                                            <div className="card-arrow-container d-flex justify-content-between align-items-center">
                                                <i 
                                                    className="bi bi-chevron-left arrow h2"
                                                    onClick={() => setThumbIdx(prevIdx => calculateIdx(prevIdx, -1))}
                                                />
                                                <i 
                                                    className="bi bi-chevron-right arrow h2"
                                                    onClick={() => setThumbIdx(prevIdx => calculateIdx(prevIdx, 1))}
                                                />
                                            </div>
                                        ) :
                                        <></>
                                }
                            </>
                        ) :
                        <></>
                }
            </div>
            <div className="py-4 px-3">
                <h2 className="text-center">{title}</h2>
                {body}
            </div>
        </div>
    );
}

Card.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.node.isRequired,
    thumbnail: PropTypes.array,
};

export default Card;