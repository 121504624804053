const events = [
    {
        title: "Winter Study Break",
        dateStart: new Date("2020-12-09"),
        dateEnd: new Date("2020-12-09"),
        description: "SEB's very first official event was held during the middle \
        of the COVID pandemic, and, as such, had to be online. During this event, \
        we had performances from Resonance, Syncopasian, and the Logarhythms, and \
        attendees were each given the opportunity to write three messages to anybody \
        that would then be physically delievered via a personalized letter.",
    },
    {
        title: "BEAM",
        dateStart: new Date("2021-10-14"),
        dateEnd: new Date("2021-10-16"),
        thumbnail: [
            "/img/events/beam.jpg",
            "/img/events/beam_poster.jpg"
        ],
        description: "BEAM was an three-day long interactive art display that was SEB's first \
        major event after the MIT commmunity had fully returned during the COVID \
        pandemic. The exhibit, in conjunction with food trucks, was meant to be \
        a way for students to destress.",
        photoLink: "https://www.dropbox.com/sh/hrmwsip2gz16yvu/AABbiYPn8c8GwDXnxiusurd9a?dl=0&fbclid=IwAR0rQ4Pb2ip3You-XANw7f1ZqZK4t7szu2ZeYmIj-UgfXvmxu4jH0TSxBZc",
    },
    {
        title: "Tech Twinkles",
        dateStart: new Date("2021-12-01"),
        dateEnd: new Date("2021-12-01"),
        thumbnail: [
            "/img/events/tech_twinkles_21.jpg",
            "/img/events/tech_twinkles_poster_21.jpg"
        ],
        description: "Tech Twinkles is a deep-rooted MIT tradition. First organized by UA Events \
        (SEB's predecessor), SEB further expanded on the event to include the greater MIT community. \
        With free mugs, food, and incredible performances by Resonance, Chorallaries, Asymtpones, ADT, \
        Bhangra, and Cello World, Tech Twinkles '21 was an unforgettable event.",
        photoLink: "https://www.dropbox.com/sh/eddvx98cjf7ip23/AAC3T5YC8lHNHxb5t-GZZpoea/SEB%20-%20Tech%20Twinkles%202021?dl=0&subfolder_nav_tracking=1",
    },
    {
        title: "SpringFest",
        dateStart: new Date("2022-04-29"),
        dateEnd: new Date("2022-04-30"),
        thumbnail: [
            "/img/events/springfest_concert_22.jpg", 
            "/img/events/springfest_student_showcase_22.jpg",
            "/img/events/springfest_carnival_22.jpg",
            "/img/events/springfest_poster_22.jpg"
        ],
        description: "SpringFest was a two-day long action-packed event with a Student Showcase, \
        a Carnival, and a Concert. The Student Showcase had 12 amazing performances from members of the \
        MIT community and free boba. The Carnival contained student-run booths, an obstacle course, a \
        jousting arena, and mechanical bulls. Finally, the Concert was headlined by Tai Verdes and opened by \
        Bren Joy and MIT's very own Miguel Gomez-Garcia. With thousands of MIT community members in attendance \
        at all of the events, the campus was revitalized just in time for the oncoming finals season.",
        photoLink: "https://drive.google.com/drive/folders/1M8zHZcTku5aD79CQDhN8RHhMmQTUOrZj?usp=sharing",
    },
    {
        title: "Oktoberfest",
        dateStart: new Date("2022-10-22"),
        dateEnd: new Date("2022-10-22"),
        thumbnail: [
            "/img/events/oktoberfest_pumpkins.jpg",
            "/img/events/oktoberfest_pin.jpg",
            "/img/events/oktoberfest_poster.jpg"
        ],
        description: "Oktoberfest was the first SEB fall-themed event that had Puppy Lab, face painting, caricature \
        painting, game booths, pumpking painting, fall-themed movies, and plenty of food and drinks for \
        the campus to enjoy. The event was a great way for MIT to relax from the stress of midterms and transition into autumn!",
    },
    {
        title: "Dunkin' Winter Wonderland",
        dateStart: new Date("2022-11-17"),
        dateEnd: new Date("2022-11-18"),
        thumbnail: [
            "/img/events/dunkin_wonderland_drinks_22.jpg",
            "/img/events/dunkin_wonderland_poster_22.jpg"
        ],
        description: "Dunkin' Winter Wonderland was a two-day event put together through a collaboration between MIT \
        SEB and Dunkin'. The MIT community was invited to try Dunkin's new seasonal drinks and donuts from the comfort of \
        heated tents. Attendees were also given free insulated mugs/gift cards and could even have their own picture printed \
        onto one of the seasonal donuts!"
    },
    {
        title: "Tech Twinkles",
        dateStart: new Date("2022-11-28"),
        dateEnd: new Date("2022-11-28"),
        thumbnail: [
            "/img/events/tech_twinkles_22.jpg",
            "/img/events/tech_twinkles_mugs_22.jpg",
            "/img/events/tech_twinkles_poster_22.jpg"
        ],
        description: "As part of MIT's yearly tradition, SEB organized an especially festive Tech Twinkles. This year, there were performances \
        from MIT Ohms, the Chorallaries, Logs, Fixation, Ballroom Dance, Resonance, Cello World, ADT, and Festival Jazz. \
        At the event, attendees were given an entirely redesigned mug and plenty of food to enjoy the performances!",
        photoLink: "https://drive.google.com/drive/folders/1Gx4bS7E-7ZQ5uNPgjWxbp7NZ-I64RBh5?usp=share_link"
    },
    {
        title: "SpringFest",
        dateStart: new Date("2023-05-05"),
        dateEnd: new Date("2023-05-06"),
        thumbnail: [
            "/img/events/springfest_poster_23.jpg"
        ],
        description: "This year's SpringFest was a two-day long event with an exciting Carnival and an explosive Concert. The Carnival had over ten \
        student-run booths, face painters, sketch artists, boba, carnival food, a mechanical bull, and plenty of other exhilarating activites \
        for MIT to enjoy. The Concert was headlined by Doechii and was opened by MIT's very own talent, with \
        INTO THE AM feat. ryan micah, Misuse of Institute Property, and Empowered Beats bringing stunning performances. \
        With thousands of members of the MIT commmunity in attendance of both events, SpringFest '23 was one for the history books.",
        photoLink: "https://drive.google.com/drive/folders/1JXkFAQkK4KwL--9zv6iPQDghD20RUZsI?usp=share_link"
    },
    {
        title: "SpookFest",
        dateStart: new Date("2023-10-13"),
        dateEnd: new Date("2023-10-13"),
        thumbnail: [
            "/img/events/spookfest_seb.jpg",
            "/img/events/spookfest_drawing.jpg",
            "/img/events/spookfest_group.jpg"
        ],
        description: "To kick off our events for the 2023 school year, we began with \
        SpookFest, a halloween-themed event. Spookfest featured giant halloween inflatables, \
        apple cider donuts, and many student groups each serving their own cultural food. \
        Students also stopped by our polaroid booth to take pictures with their friends after coming home from class!",
        photoLink: "https://drive.google.com/drive/folders/1-1mqHuOSpqZAbE3HCeawOgoozPM_rbsB?usp=sharing"
    },
    {
        title: "Dunkin' Collaboration",
        dateStart: new Date("2023-10-23"),
        dateEnd: new Date("2023-10-23"),
        thumbnail: [
            "/img/events/dunkin_mural.jpg",
            "/img/events/dunkin_swing.jpg"
        ],
        description: "To start getting into the fall mood, SEB collabed with Dunkin Donuts \
        to throw a community event. Attendees got free coffee and dunkin’ merch. The event \
        also featured a Cambridge mural which students helped paint."
    },
    {
        title: "Tech Twinkles",
        dateStart: new Date("2023-12-09"),
        dateEnd: new Date("2023-12-09"),
        thumbnail: [
            "/img/events/tech_twinkles_23_poster.jpg",
            "/img/events/tech_twinkles_23_mugs.jpg",
            "/img/events/tech_twinkles_23_photo.jpg"
        ],
        description: "Every year SEB throws a holiday themed event called tech twinkles. With \
        performances from many different student groups, delicious food, and good vibes, Tech Twinkles \
        has become an essential campus tradition. Every year a new mug is designed according to the theme, \
        so you can collect a mug for every year you are here!"
    },
    {
        title: "Wellbeing Saturdays",
        dateStart: new Date("2024-03-16"),
        dateEnd: new Date("2024-04-27"),
        thumbnail: [
            "/img/events/wellbeing_24_insta.jpeg",
            "/img/events/wellbeing_24_flowers.jpg",
            "/img/events/wellbeing_24_paint.jpg"
        ],
        description: "During Spring ‘24, SEB began collaborating with the DoingWell initiative at MIT! \
        Activities included a paint night social, Springfest artist listening party, flower bouquet making, \
        jewelry crafting, and more. Events were held in the WellBeing Lab with music and snacks provided. The Wellbeing \
        Saturday events were a go-to spot for students to hang out with other students and unwind!"
    },
    {
        title: "SpringFest",
        dateStart: new Date("2024-05-03"),
        dateEnd: new Date("2024-05-04"),
        thumbnail: [
            "/img/events/springfest_concert_24.jpg"
        ],
        description: "This year’s SpringFest was an unforgettable weekend packed with  energy, creativity, and excitement. \
        The Carnival had a variety of student-run booths, sketch artists, shaved ice, fried dough, and many other attractions, \
        like a mechanical bull and an inflatable obstacle course. The Concert was a major highlight with two-time Grammy-nominated \
        artist Cordae taking the stage! MIT’s own talent set the tone for the evening, with MIT Live, Nick Casetti, Stewart Issacs, \
        and Love and a Sandwich opening the concert.",
        photoLink: "https://drive.google.com/drive/folders/17NVvnmi_0_Htc6WrVOQYpXVeo4wPLiu6?usp=sharing"
    },
    {
        title: "FallFest",
        dateStart: new Date("2024-10-05"),
        dateEnd: new Date("2024-10-05"),
        thumbnail: [
            "/img/events/fallfest_24_pumpkin.jpg",
            "/img/events/fallfest_24_movie.jpg",
            "/img/events/fallfest_24_andy.jpg"
        ],
        description: "We kicked off our 2024-2025 events with FallFest! We celebrated the start of fall by \
        hosting an inflatable movie theater on Kresge, where students could come by, lay picnic blankets down, and enjoy \
        Inside Out 2. We also brought back an old staple of comedy events by inviting Andy Woodhull to close out with  \
        a comedy show. Thank you to the De Florez Humor Fund and UA for sponsoring the event, and Mixed@MIT, Camp Kesem, LCC, \
        ASA, and Chocolate City for providing great food!",
        photoLink: "https://www.dropbox.com/scl/fo/s5p7yckwtbk25qggoazi4/AFlyM46IsNpFGyqUf-e2dEs?rlkey=odiy5jrh33mg84oseqxz94qoj&e=1&st=q36kcfto&dl=0"
    }
];

export default events;
