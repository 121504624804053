/* eslint-disable no-unused-vars */
import React, {useEffect, useRef, useState} from "react";
import Header from "../components/Header";
import NextEvent from "../components/NextEvent";
import Footer from "../components/Footer";
import "./home.scss";

const maxBrightness = 1;

function Home() {
    const [brightness, setBrightness] = useState(maxBrightness);

    const video = useRef();
    
    function onScroll() {
        const newBright = Math.max(0, (1 - (window.pageYOffset / video.current.clientHeight) * 2) * maxBrightness);
        setBrightness(newBright);
    }

    useEffect(() => {
        document.title = "MIT Student Events Board";
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);


    return (
        <div>
            <Header transparent={true} active={0}/>
            <div className="hero-container center">
                <video className="hero-video" style={{filter: `blur(10px) brightness(${brightness})`}} ref={video} autoPlay loop muted>
                    <source src="img/seb-hero.mp4" type="video/mp4" />
                </video>
                <div style={{zIndex: 1}}>
                    <img className="hero-image" src="img/logo.png" />
                </div>
            </div>
            <NextEvent />
            <Footer />
        </div>
    );
}

export default Home;
