/* eslint-disable no-unused-vars */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBGgagJLLJCmi4I15ELT0bTtkovObTfcTo",
    authDomain: "seb-website-dfcf4.firebaseapp.com",
    projectId: "seb-website-dfcf4",
    storageBucket: "seb-website-dfcf4.appspot.com",
    messagingSenderId: "883191859186",
    appId: "1:883191859186:web:4f49ae5fd53cbdb0a3cd6b",
    measurementId: "G-QNMT56WM3Z"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// const db = getFirestore(app);

export default firebase;