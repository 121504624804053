/* eslint-disable no-unused-vars */
import { doc, setDoc, getFirestore } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, setPersistence, browserLocalPersistence, onAuthStateChanged } from "firebase/auth";
import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import firebase from "../common/firebase";
import "./redirect.scss";

// eslint-disable-next-line no-useless-escape
const urlReg = new RegExp("^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$");
const keywordReg = new RegExp("[-_a-z0-9]+");
const auth = getAuth();

function NewRedirect() {
    const [searchParams, setSearchParams] = useSearchParams();

    const [keyword, setKeyword] = useState(searchParams.get("keyword") ?? "");
    const [url, setUrl] = useState(searchParams.get("url") ? searchParams.get("url").replace(/%amp/g, "&") : "");

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loggedIn, setLoggedIn] = useState(auth.currentUser ? true : false);

    const db = getFirestore(firebase);
    const navigate = useNavigate();

    document.title = "MIT Student Events Board | New Redirect";

    useEffect(() => {
        navigate("/r/new");
    }, []);

    onAuthStateChanged(auth, user => {
        if (user && !loggedIn) {
            setLoggedIn(true);
        }
    });

    function handleSubmit(event) {
        event.preventDefault();
        if (!loggedIn) {
            alert("I don't know how you got past it, but you need to be logged in to submit");
            return;
        }
        if (!keywordReg.test(keyword)) {
            alert("Keyword length must be greater than 0 and can only contain characters 'a-z', '0-9', '-', and '_'");
            return;
        }
        if (!urlReg.test(url)) {
            alert("Must submit a valid URL");
            return;
        }

        setDoc(doc(db, "redirects", keyword), {url: url})
            .then(res => {
                alert("Submission success!");
                setKeyword("");
                setUrl("");
            }).catch(err => {
                alert("Submission failed; check console for more information");
                console.error(err);
            });
    }

    function handleLogin(event) {
        event.preventDefault();

        setPersistence(auth, browserLocalPersistence)
            .then(() => {
                signInWithEmailAndPassword(auth, username, password)
                    .then((res) => {
                        setLoggedIn(true);
                    })
                    .catch((err) => {
                        alert("Log in failed");
                        console.error(err);
                    });
            })
            .catch((err) => {
                console.error(err);
            });
    }

    if (loggedIn) {
        return (
            <div className="full center bg-black">
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <input className="text-input white-text" onChange={(event) => setKeyword(event.target.value)} value={keyword} type="text" placeholder="Keyword" />
                    <br></br>
                    <input className="text-input white-text" onChange={(event) => setUrl(event.target.value)} value={url} type="text" placeholder="URL" />
                    <br></br>
                    <input className="submit-input white-text" type="submit" value="Submit" />
                </form>
            </div>
        );
    }

    return (
        <div className="full center bg-black">
            <h1 className="white-text pb-3">Log in to create a redirection</h1>
            <form onSubmit={handleLogin}>
                <input className="text-input white-text" onChange={(event) => setUsername(event.target.value)} value={username} type="email" placeholder="Username" />
                <br></br>
                <input className="text-input white-text" onChange={(event) => setPassword(event.target.value)} value={password} type="password" placeholder="Password" />
                <br></br>
                <input className="submit-input white-text" type="submit" value="Log in" />
            </form>
        </div>
    );
}

export default NewRedirect;
