import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "./home.scss";

function Recruitment() {
    document.title = "MIT Student Events Board | Recruitment";
    return (
        <div>
            <Header transparent={true} active={0}/>
            <div className="bg-black full center">
                <h1 className="display-1 text-light">Recruitment</h1>
            </div>
            <div className="container py-5">
                <div className="row text-center pb-3">
                    <h1>Interested in joining SEB?</h1>
                </div>
                <div className="row pb-3">
                    <div className="col-12">
                        <p>
                            Applications to join SEB in the 2024-2025 academic year are now open!
                            If interested in applying, please check out our Activity Midway booth this Friday, August 30<sup>th</sup>, from
                            2:30 to 4:00 PM. We will also be having a meet and greet in collaboration with the UA
                            on Wednesday, September 4<sup>th</sup>, at the Student Center Plaza from 3:00 to 5:00 PM.
                        </p>
                        <p>
                            The recruitment process will begin with a brief written application submitted via a Google Form. 
                            After a review of the applications, we will reach out to applicants to schedule rolling interviews.
                        </p>
                        <p>
                            Please click on <a href="https://forms.gle/yc9h2CT3xTEUzJro7">this link</a> to apply. <strong>Applications 
                            are due Friday, September 6<sup>th</sup>, 11:59 PM.</strong>
                        </p>
                        <p>
                            If you are interested in learning more, you can contact us 
                            at <a href="mailto:student-events@mit.edu">student-events@mit.edu</a>
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Recruitment;
