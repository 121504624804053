import React from "react";
import PropTypes from "prop-types";
import "./member.scss";

function Member( { title, position, body, image } ) {
    return (
        <div className="py-4 col-sm-6 col-md-3 text-center">
            <div className="mb-3 headshot-container">
                <img className="headshot" src={image} />
                <div className="outline"></div>
            </div>
            <h3>{title}</h3>
            <h5 className="text-secondary">{position}</h5>
            {body}
        </div>
    );
}

Member.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.node.isRequired,
    image: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
};

export default Member;