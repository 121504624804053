import React from "react";
import ReactDOM from "react-dom/client";
import "./common/bootstrap.scss";
import "./common/main.scss";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
    //     <App />
    // </React.StrictMode>
    <App />
);
