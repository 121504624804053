const team = {
    "Current": [
        {
            name: "Olivia Beniston",
            image: "/img/board/olivia_beniston.jpg",
            position: "President",
            email: "olivi327@mit.edu",
        },
        {
            name: "Wilhem Hector",
            image: "/img/board/wilhem_hector.jpg",
            position: "President",
            email: "wilhem@mit.edu",
        },
        {
            name: "Angelica Castro-Salazar",
            image: "/img/board/angelica_castro.jpg",
            position: "Marketing Chair",
            email: "amcs@mit.edu",
        },
        {
            name: "Sydney Smith",
            image: "/img/board/sydney_smith.jpg",
            position: "Treasurer",
            email: "sls890@mit.edu",
        },
        {
            name: "Krithik Ramesh",
            image: "/img/board/krithik_ramesh.jpg",
            position: "Board",
            email: "krithik@mit.edu",
        },
        {
            name: "Lex Coston",
            image: "/img/board/lex_coston.jpg",
            position: "Board",
            email: "coston@mit.edu",
        },
        {
            name: "Sharon Zhu",
            image: "/img/board/sharon_zhu.jpg",
            position: "Board",
            email: "sharonxz@mit.edu",
        },
        {
            name: "Alicia Chen",
            image: "/img/board/alicia_chen.jpeg",
            position: "Board",
            email: "aliciasc@mit.edu",
        },
        {
            name: "Ruben Stephen",
            image: "/img/board/ruben_stephen.jpg",
            position: "Board",
            email: "rsteph04@mit.edu",
        },
        {
            name: "Anica Liu",
            image: "/img/board/unknown.webp",
            position: "Board",
            email: "anicaliu@mit.edu",
        },
        {
            name: "Ayyoob Berhane",
            image: "/img/board/ayyoob_berhane.jpg",
            position: "Board",
            email: "ayyoob@mit.edu",
        },
        {
            name: "Michelle Zhang",
            image: "/img/board/michelle_zhang.jpg",
            position: "Board",
            email: "mshzhang@mit.edu",
        },
        {
            name: "Jessica Zheng",
            image: "/img/board/jessica_zheng.jpg",
            position: "Vice President",
            email: "j_zheng@mit.edu",
        },
        {
            name: "Vivian Trinh",
            image: "/img/board/vivian_trinh.jpg",
            position: "Board",
            email: "viviantt@mit.edu",
        },
        {
            name: "Christohpher Legerme",
            image: "/img/board/unknown.webp",
            position: "Board",
            email: "legerme@mit.edu",
        },
        {
            name: "Maya Zavatsky",
            image: "/img/board/unknown.webp",
            position: "Board",
            email: "mrz_sky@mit.edu",
        },
        {
            name: "Grace Zhang",
            image: "/img/board/grace_zhang.jpg",
            position: "Board",
            email: "zgrace@mit.edu",
        },
        {
            name: "Cassandra Tafuri Del Vecchio",
            image: "/img/board/cassandra_tafuri.png",
            position: "Board",
            email: "cassietd@mit.edu",
        },
        {
            name: "Lia Gonzales",
            image: "/img/board/lia_gonzales.jpg",
            position: "Board",
            email: "tvgonzal@mit.edu",
        },
        {
            name: "Michelle Sotelo",
            image: "/img/board/michelle_sotelo.jpg",
            position: "Board",
            email: "msotelo@mit.edu",
        },
        {
            name: "Juan Flores",
            image: "/img/board/unknown.webp",
            position: "Board",
            email: "floresj1@mit.edu",
        },
    ],
    "2023-2024": [
        {
            name: "Olivia Beniston",
            image: "/img/board/olivia_beniston.jpg",
            position: "President",
            email: "olivi327@mit.edu",
        },
        {
            name: "Wilhem Hector",
            image: "/img/board/wilhem_hector.jpg",
            position: "President",
            email: "wilhem@mit.edu",
        },
        {
            name: "Kyna McGill",
            image: "/img/board/kyna_mcgill.jpg",
            position: "Vice President",
            email: "kyna@mit.edu",
        },
        {
            name: "Ugo Okwuadigbo",
            image: "/img/board/unknown.webp",
            position: "Treasurer",
            email: "ujo@mit.edu",
        },
        {
            name: "Angelica Castro-Salazar",
            image: "/img/board/angelica_castro.jpg",
            position: "Marketing Chair",
            email: "amcs@mit.edu",
        },
        {
            name: "Kelly Lu",
            image: "/img/board/kelly_lu.jpg",
            position: "Board",
            email: "kellylu@mit.edu",
        },
        {
            name: "Sydney Smith",
            image: "/img/board/sydney_smith.jpg",
            position: "Board",
            email: "sls890@mit.edu",
        },
        {
            name: "Krithik Ramesh",
            image: "/img/board/krithik_ramesh.jpg",
            position: "Board",
            email: "krithik@mit.edu",
        },
        {
            name: "Lex Coston",
            image: "/img/board/lex_coston.jpg",
            position: "Board",
            email: "coston@mit.edu",
        },
        {
            name: "Sharon Zhu",
            image: "/img/board/sharon_zhu.jpg",
            position: "Board",
            email: "sharonxz@mit.edu",
        },
        {
            name: "Alicia Chen",
            image: "/img/board/alicia_chen.jpeg",
            position: "Board",
            email: "aliciasc@mit.edu",
        },
        {
            name: "Ruben Stephen",
            image: "/img/board/ruben_stephen.jpg",
            position: "Board",
            email: "rsteph04@mit.edu",
        },
        {
            name: "Anica Liu",
            image: "/img/board/unknown.webp",
            position: "Board",
            email: "anicaliu@mit.edu",
        },
        {
            name: "Ayyoob Berhane",
            image: "/img/board/ayyoob_berhane.jpg",
            position: "Board",
            email: "ayyoob@mit.edu",
        },
        {
            name: "Daniel Ige",
            image: "/img/board/daniel_ige.jpg",
            position: "Board",
            email: "danige@mit.edu",
        },
        {
            name: "Michelle Zhang",
            image: "/img/board/michelle_zhang.jpg",
            position: "Board",
            email: "mshzhang@mit.edu",
        },
        {
            name: "Jessica Zheng",
            image: "/img/board/jessica_zheng.jpg",
            position: "Board",
            email: "j_zheng@mit.edu",
        },
        {
            name: "Vivian Trinh",
            image: "/img/board/vivian_trinh.jpg",
            position: "Board",
            email: "viviantt@mit.edu",
        },
    ],
    "2022-2023": [
        {
            name: "Sean Knight",
            image: "/img/board/sean_knight.jpg",
            position: "President",
            email: "seknight@mit.edu",
        },
        {
            name: "Emily Sologuren",
            image: "https://superurop.mit.edu/wp-content/uploads/2021/09/Sologuren_Emily.jpg",
            position: "President",
            email: "esolo@mit.edu",
        },
        {
            name: "Tejal Reddy",
            image: "/img/board/unknown.webp",
            position: "Vice President",
            email: "reddyt@mit.edu",
        },
        {
            name: "Olivia Beniston",
            image: "/img/board/olivia_beniston.jpg",
            position: "Treasurer",
            email: "olivi327@mit.edu",
        },
        {
            name: "Wilhem Hector",
            image: "/img/board/wilhem_hector.jpg",
            position: "Marketing Chair",
            email: "wilhem@mit.edu",
        },
        {
            name: "Kelly Lu",
            image: "/img/board/kelly_lu.jpg",
            position: "Board",
            email: "kellylu@mit.edu",
        },
        {
            name: "Alexis Yang",
            image: "/img/board/unknown.webp",
            position: "Board",
            email: "asyang@mit.edu",
        },
        {
            name: "Kyna McGill",
            image: "/img/board/kyna_mcgill.jpg",
            position: "Board",
            email: "kyna@mit.edu",
        },
        {
            name: "Fiona Cai",
            image: "/img/board/unknown.webp",
            position: "Board",
            email: "fcai@mit.edu",
        },
        {
            name: "Ashley Williams",
            image: "/img/board/ashley_williams.jpg",
            position: "Board",
            email: "ashwill@mit.edu",
        },
        {
            name: "Alicia Chen",
            image: "/img/board/unknown.webp",
            position: "Board",
            email: "aliciasc@mit.edu",
        },
        {
            name: "Angelica Castro-Salazar",
            image: "/img/board/angelica_castro.jpg",
            position: "Board",
            email: "amcs@mit.edu",
        },
        {
            name: "Anica Liu",
            image: "/img/board/unknown.webp",
            position: "Board",
            email: "anicaliu@mit.edu",
        },
        {
            name: "Ayyoob Berhane",
            image: "/img/board/ayyoob_berhane.jpg",
            position: "Board",
            email: "ayyoob@mit.edu",
        },
        {
            name: "Chengfei Yan",
            image: "/img/board/unknown.webp",
            position: "Board",
            email: "cfyan831@mit.edu",
        },
        {
            name: "Michelle Zhang",
            image: "/img/board/michelle_zhang.jpg",
            position: "Board",
            email: "mshzhang@mit.edu",
        },
        {
            name: "Katrina Li",
            image: "/img/board/unknown.webp",
            position: "Board",
            email: "katrinal@mit.edu",
        },
    ],
    "2021-2022": [
        {
            name: "Robert Law",
            image: "/img/board/robert_law.jpg",
            position: "Chair"
        },
        {
            name: "Sean Knight",
            image: "/img/board/sean_knight.jpg",
            position: "Vice Chair"
        },
        {
            name: "Maria Fedyk",
            image: "/img/board/unknown.webp",
            position: "Treasurer"
        },
        {
            name: "Alexis Yang",
            image: "/img/board/unknown.webp",
            position: "Board",
        },
        {
            name: "Cami Meija",
            image: "/img/board/unknown.webp",
            position: "Board",
        },
        {
            name: "Emily Sologuren",
            image: "https://superurop.mit.edu/wp-content/uploads/2021/09/Sologuren_Emily.jpg",
            position: "Board",
        },
        {
            name: "Fiona Cai",
            image: "/img/board/unknown.webp",
            position: "Board",
        },
        {
            name: "Jane Bai",
            image: "/img/board/unknown.webp",
            position: "Board",
        },
        {
            name: "Kelly Lu",
            image: "/img/board/kelly_lu.jpg",
            position: "Board",
        },
        {
            name: "Kyna McGill",
            image: "/img/board/unknown.webp",
            position: "Board",
        },
        {
            name: "Olivia Beniston",
            image: "/img/board/unknown.webp",
            position: "Board",
        },
        {
            name: "Tejal Reddy",
            image: "/img/board/unknown.webp",
            position: "Board",
        },
        {
            name: "Wilhem Hector",
            image: "/img/board/wilhem_hector.jpg",
            position: "Board",
        },
        {
            name: "Yuya Makino",
            image: "/img/board/unknown.webp",
            position: "Board",
        },
    ],
    "2020-2021": [
        {
            name: "Robert Law",
            image: "/img/board/robert_law.jpg",
            position: "Chair"
        },
        {
            name: "George Bian",
            image: "/img/board/unknown.webp",
            position: "Vice Chair"
        },
        {
            name: "Maria Fedyk",
            image: "/img/board/unknown.webp",
            position: "Treasurer"
        },
        {
            name: "Andrea Badillo",
            image: "/img/board/unknown.webp",
            position: "Board",
        },
        {
            name: "Catherine Washburn",
            image: "/img/board/unknown.webp",
            position: "Board",
        },
        {
            name: "Fiona Cai",
            image: "/img/board/unknown.webp",
            position: "Board",
        },
        {
            name: "Hannah Kim",
            image: "/img/board/unknown.webp",
            position: "Board",
            email: "seknight@mit.edu",
        },
        {
            name: "Jason Lee",
            image: "/img/board/unknown.webp",
            position: "Board",
        },
        {
            name: "Kathryn Tso",
            image: "/img/board/unknown.webp",
            position: "Board",
        },
        {
            name: "Jane Bai",
            image: "/img/board/unknown.webp",
            position: "Board",
        },
        {
            name: "Sean Knight",
            image: "/img/board/sean_knight.jpg",
            position: "Board",
        },
        {
            name: "Tejal Reddy",
            image: "/img/board/unknown.webp",
            position: "Board",
        },
        {
            name: "Teodora Stan",
            image: "/img/board/unknown.webp",
            position: "Board",
        },
    ]
};

const timeSort = (a, b) => {
    if (a === "Current" && b !== "Current") {
        return -1;
    }

    if (a !== "Current" && b === "Current") {
        return 1;
    }

    if (a === b) {
        return 0;
    }

    const yearA = parseInt(a.split("-"));
    const yearB = parseInt(b.split("-"));
    if (yearA > yearB) {
        return -1;
    }
    if (yearA < yearB) {
        return 1;
    }
    return 0;
};

const priority = {
    "Chair": 0,
    "President": 0,
    "Vice President": 1,
    "Vice Chair": 1,
    "Treasurer": 2,
    "Marketing Chair": 3,
    "Board": 4
};

const teamSort = (a, b) => {
    if (priority[a.position] < priority[b.position])
        return -1;
    if (priority[a.position] > priority[b.position]) 
        return 1;
    if (a.name < b.name) 
        return -1;
    if (a.name > b.name) 
        return 1;
    return 0;
};

export {team, timeSort, teamSort};