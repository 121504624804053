import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./routes/Home";
import Board from "./routes/Board";
import Events from "./routes/Events";
import Recruitment from "./routes/Recruitment";
import Redirect from "./routes/Redirect";
import NotFound from "./routes/NotFound";
import NewRedirect from "./routes/NewRedirect";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<Home />}></Route>
                <Route path="/board/" element={<Board />}></Route>
                <Route path="/events/" element={<Events />}></Route>
                <Route path="/recruitment/" element={<Recruitment />}></Route>
                <Route path="/r/new" element={<NewRedirect />}></Route>
                <Route path="/r/:keyword" element={<Redirect />}></Route>
                <Route path="/*" element={<NotFound />}></Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
