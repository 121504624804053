/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Member from "../components/Member";
import { team, timeSort, teamSort } from "../common/team";
import "./home.scss";

const years = Object.keys(team).sort(timeSort);

function Board() {
    const [selectedYear, setSelectedYear] = useState("Current");

    document.title = "MIT Student Events Board | Board";

    return (
        <div>
            <Header transparent={true} active={0}/>
            <div className="bg-black full center">
                <h1 className="display-1 text-light">Meet the Board</h1>
            </div>
            
            <div className="py-5 container px-4">
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <h1 className="text-center pb-4">{selectedYear} Board</h1>
                        <select className="form-select mb-4" value={selectedYear} onChange={(event) => setSelectedYear(event.target.value)}>
                            {
                                years.map((year, idx) => {
                                    return (
                                        <option key={`select-${idx}`} value={year}>{year}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-3"></div>
                </div>
                <div className="row gx-5">
                    {
                        team[selectedYear].sort(teamSort).map((member, idx) => {
                            let body;
                            if (selectedYear === "Current") {
                                body = (
                                    <a href={`mailto:${member.email}`}>{member.email}</a>
                                );
                            }
                            return (
                                <Member key={`member-${idx}`} title={member.name} image={member.image} position={member.position} body={body} />
                            );
                        })
                    }
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Board;
