/* eslint-disable no-unused-vars */
import { doc, getDoc, getFirestore } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import firebase from "../common/firebase";
import Loading from "../components/Loading";

function Redirect() {
    const { keyword } = useParams();
    const [loading, setLoading] = useState(true);
    const db = getFirestore(firebase);

    useEffect(() => {
        getDoc(doc(db, "redirects", keyword))
            .then(res => {
                if (res.exists()) {
                    window.location.href = res.data().url;
                } else {
                    setLoading(false);
                }
            }).catch(err => {
                console.error(err);
            });
    });
    
    if (loading) {
        document.title = "Loading Redirect...";
        return (
            <div className="full center bg-black">
                <Loading />
            </div>
        );
    }
    
    document.title = "Redirect Not Found";
    return (
        <div className="full center bg-black">
            <h1 className="text-white">Could not find &apos;{keyword}&apos;</h1>
            <a href={`/r/new?keyword=${keyword}`}>Click to create a new redirect for &apos;{keyword}&apos;</a>
        </div>
    );
}

export default Redirect;
