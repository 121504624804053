import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import "./header.scss";

const links = [
    {
        text: "Home",
        link: "/",
    },
    {
        text: "Events",
        link: "/events/",
    },
    {
        text: "Board",
        link: "/board/",
    },
    {
        text: "Recruitment",
        link: "/recruitment/",
    },
];

function Header({transparent = true}) {
    const [isTransparent, setIsTransparent] = useState(transparent);
    const [menuOpen, setMenuOpen] = useState(false);

    const location = useLocation();

    function onScroll() {
        if (window.pageYOffset > 0) {
            setIsTransparent(false);
        }
        else if (window.pageYOffset === 0 && transparent) {
            setIsTransparent(true);
        }
    }

    function textColorClass(link) {
        if (link === location.pathname) {
            return isTransparent ? "text-light transition-text-color" : "text-primary transition-text-color";
        }
        return isTransparent ? "text-light transition-text-color" : "text-dark transition-text-color";
    }

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);


    const headerClass = "header py-3 px-3 " + (isTransparent ? "transparent" : "");

    if (!menuOpen) {
        document.body.style.overflow = "auto";
        return (
            <div className={headerClass}>
                <div className="d-none d-md-block container">
                    <div className="row text-center">
                        {
                            links.map((item, idx) => {
                                const textClass = textColorClass(item.link);
                                return (
                                    <div key={`header-md-${idx}`} className={`col-sm-${Math.floor(12 / links.length)}`}>
                                        <a className="text-decoration-none" href={item.link}><h2 className={textClass}>{item.text}</h2></a>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
                <div className="d-md-none container">
                    <div className="row text-center">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <a className="text-decoration-none" href="/">
                                <h1 className={textColorClass("")}>MIT SEB</h1>
                            </a>
                        </div>
                        <div className="col-1">
                            <h1 className="pointer" onClick={() => setMenuOpen(true)}><i className={`bi bi-list ${textColorClass("")}`}></i></h1>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else {
        document.body.style.overflow = "hidden";
        return (
            <div className="mobile-menu full">
                <div className="pt-3 px-3 mobile-menu-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-1"></div>
                            <div className="col-10 text-center">
                                <a className="text-decoration-none" href="/">
                                    <h1 className="text-light transition-text-color">MIT SEB</h1>
                                </a>
                            </div>
                            <div className="col-1">
                                <h1 className="pointer" onClick={() => setMenuOpen(false)}><i className="bi bi-x text-light"></i></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column full mobile-links justify-content-center">
                    {
                        links.map((item, idx) => {
                            const textClass = item.link === location.pathname ? "text-primary" : "text-light";
                            return (
                                <div key={`header-sm-${idx}`} className="row text-center py-2">
                                    <a className="text-decoration-none" href={item.link}><h1 className={textClass}>{item.text}</h1></a>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }
}

Header.propTypes = {
    transparent: PropTypes.bool,
};

export default Header;