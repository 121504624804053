import React from "react";
import Header from "../components/Header";
import events from "../common/events";
import Card from "../components/Card";
import Footer from "../components/Footer";
import "./home.scss";

const sortedEvents = [...events];
sortedEvents.sort((a, b) => {
    if (a.dateStart < b.dateStart)
        return -1;
    if (a.dateStart > b.dateStart)
        return 1;
    return 0;
}).reverse();

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

function Events() {
    document.title = "MIT Student Events Board | Events";

    return (
        <div>
            <Header transparent={true} active={0}/>
            <div className="bg-black full center">
                <h1 className="display-1 text-light">Past Events</h1>
            </div>
            <div className="container py-5">
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        {
                            sortedEvents.map((event, idx) => {
                                const body = (
                                    <div className="container">
                                        <div className="row">
                                            <p>{event.description}</p>
                                            {
                                                event.photoLink ?
                                                    <a className="mt-3 text-center" href={event.photoLink}>View Photos</a> :
                                                    <></>
                                            }
                                        </div>
                                    </div>
                                );
                                const year = event.dateStart.getFullYear().toString().slice(2);
                                const title = `${event.title} (${monthNames[event.dateStart.getMonth()]} '${year})`;
                                
                                return <Card 
                                    key={`event-card-${idx}`}
                                    title={title}
                                    body={body}
                                    thumbnail={event.thumbnail ?? []}
                                />;
                            })
                        }
                    </div>
                    <div className="col-md-3"></div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Events;
