import React from "react";
// import PropTypes from "prop-types";
import "./nextEvent.scss";

function NextEvent() {
    return (
        <div className="container py-5">
            <div className="row text-center py-3">
                <div className="col">
                    <h1>Who are we?</h1>
                </div>
            </div>

            <div className="row pb-5">
                <div className="col">
                    <p>
                        We are the Student Events Board: MIT&apos;s main fun factory. 
                        We work with SOLE and other admin offices to organize the largest events on campus.
                        If you have ever heard of SpringFest or Tech Twinkles: We make it happen!
                    </p>
                    <p>
                        The Board has 18 members, including 5 officers. Any undergraduate or graduate student is welcome to join.
                    </p>
                </div>
            </div>
            <hr />

            <div className="row text-center py-3">
                <div className="col">
                    <h1>Event Feedback &apos;24 - &apos;25</h1>
                </div>
            </div>

            <div className="row pb-5">
                <div className="col">
                    <p>
                        The Student Events Board is grateful for your support this past year. 
                        Thanks to all of you, we are able to organize fantastic events that lift up the campus spirit! 
                    </p>
                    <p>
                        We are excited about another eventful year! If you attended any of our events last year 
                        (e.g. SpringFest, Tech Twinkles, Dunkin&apos;...), we would appreciate some feedback <strong>to help us improve for this coming year, 
                        You can leave event ratings and comments <a href="https://mit.co1.qualtrics.com/jfe/form/SV_eWGZDjucrjqgQ8C">here</a>. </strong> 
                        It only takes a few minutes to fill out, but your reviews have a strong impact on the events that we 
                        choose to organize.
                    </p>
                    <p>
                        Let us have a great year!
                    </p>
                </div>
            </div>
            <hr />
            <div className="row text-center py-3">
                <div className="col">
                    <h1>Event Planning 101</h1>
                </div>
            </div>
            <div className="row pb-5">
                <div className="col">
                    <p>
                        SEB held an event on the 15th of September, 2022 to give tips to the general
                        MIT community on how they can organize their own events. If you missed the presentation, you can find the slides 
                        used <a href="https://docs.google.com/presentation/d/17zYQ9zSNeTlVzcRgjFimAjop7M9a_AcQgjWRxQQanxk/edit?usp=sharing">here</a>.
                    </p>
                    <div className="ratio ratio-16x9">
                        <iframe src="https://www.youtube.com/embed/7ru_0CAzkbo" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}

NextEvent.propTypes = {
};

export default NextEvent;