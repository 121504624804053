import React from "react";

function NotFound() {
    document.title = "MIT Student Events Board | Page Not Found";

    return (
        <div className="full center bg-black">
            <h1 className="tracked text-light">404 - Page Not Found</h1>
            <a href="/">Back to home</a>
        </div>
    );
}

export default NotFound;
