import React from "react";
import "./loading.scss";

function Loading() {
    return (
        <div className="load">
            <div className="circle1"></div>
            <div className="circle2"></div>
            <div className="circle3"></div>
            <div className="circle4"></div>
            <div className="circle5"></div>
        </div>
    );
}

export default Loading;